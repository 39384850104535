<template>
  <div>
    <MobileDosandDonts v-if="!isLaptop" />
    <LaptopDosandDonts v-else />
  </div>
</template>
<script>
import LaptopDosandDonts from "../components/DoAndDont.vue";
import MobileDosandDonts from "../components/mobileView/MobileDosDonts.vue";
export default {
  name: "TestDetails",
  data() {
    return {
      isLaptop: true,
    };
  },
  components: {
    LaptopDosandDonts,
    MobileDosandDonts,
  },
  mounted() {
    this.checkScreenSize(); // Check screen size initially
    window.addEventListener("resize", this.checkScreenSize);
    this.$store.commit("codingVUEX/savetryQuestion", false);
    this.$store.commit("codingVUEX/openCodingSection", false);
    this.$store.commit("sectionVUEX/prevnextbtnstatus", 0);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.checkScreenSize); // Remove event listener before component unmounts
  },
  methods: {
    checkScreenSize() {
      this.isLaptop = window.innerWidth >= 1024;
    },
  },
};
</script>